export const statusDataExample = [
  {
    "Tool": "FastQCPreAlign-1",
    "Stage": "Processed",
    "Progress": 100
  },
  {
    "Tool": "FastQCPreAlign-0",
    "Stage": "Processed",
    "Progress": 100
  },
  {
    "Tool": "BWA",
    "Stage": "Aligning",
    "Progress": 100
  },
  {
    "Tool": "SortSamQueryname",
    "Stage": "SinglePass",
    "Progress": 100
  },
  {
    "Tool": "MarkDuplicates",
    "Stage": "SinglePass",
    "Progress": 100
  },
  {
    "Tool": "SortSamCoordinate",
    "Stage": "SinglePass",
    "Progress": 100
  },
  {
    "Tool": "BaseRecalibrator",
    "Stage": "Processed",
    "Progress": 100
  },
  {
    "Tool": "ApplyBQSR",
    "Stage": "Processed",
    "Progress": 100
  },
  {
    "Tool": "CollectInsertSizeMetrics",
    "Stage": "SinglePass",
    "Progress": 100
  },
  {
    "Tool": "FlagStat",
    "Stage": "SinglePass",
    "Progress": 100
  },
  {
    "Tool": "FastQCFinal",
    "Stage": "Processed",
    "Progress": 100
  },
  {
    "Tool": "CollectAlignmentSummaryMetrics",
    "Stage": "SinglePass",
    "Progress": 100
  },
  {
    "Tool": "CollectHsMetrics",
    "Stage": "SinglePass",
    "Progress": 100
  },
  {
    "Tool": "DeepVariant",
    "Stage": "Annotation",
    "Progress": 0
  }
];
