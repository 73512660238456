import React from "react"

import PlusIcon from '@rsuite/icons/Plus';
import MinusIcon from '@rsuite/icons/Minus';
import IconButton from "rsuite/IconButton"
import Input from "rsuite/Input"
import ButtonGroup from 'rsuite/ButtonGroup';
import set from "object-set"

const Cell = ({ children, style, ...rest }) => (
    <td style={{ padding: '2px 4px 2px 0', verticalAlign: 'top', ...style }} {...rest}>
        {children}
    </td>
);

const FusionVariantItem = ({ rowValue = {}, onChange, rowIndex }) => {
    const handleChange = name => value => onChange(rowIndex, { ...rowValue, [name]: value })

    return (
        <tr>
            <Cell><Input value={rowValue.Gene1} onChange={handleChange("Gene1")} /></Cell>
            <Cell><Input value={rowValue.Pos1} onChange={handleChange("Pos1")} /></Cell>
            <Cell><Input value={rowValue.Gene2} onChange={handleChange("Gene2")} /></Cell>
            <Cell><Input value={rowValue.Pos2} onChange={handleChange("Pos2")} /></Cell>
            <Cell><Input value={rowValue.Reads} onChange={handleChange("Reads")} /></Cell>
        </tr>
    );
};


export function FusionInputControl({ value = [], onChange }) {

    /**
     * Gene 
     * Variant_full
     * Region
     * Frequency
     * Effect
     * Coverage
     */

    const [array, setArray] = React.useState(value);
    const handleChange = next => {
        setArray(next);
        onChange(next);
    };
    const handleInputChange = (rowIndex, value) => {
        const next = [...array];
        next[rowIndex] = value;
        handleChange(next);
    };

    const handleMinus = () => {
        handleChange(array.slice(0, -1));
    };
    const handleAdd = () => {
        handleChange(array.concat([{
            Gene1: "",
            Pos1: "",
            Gene2: "",
            Pos2: "",
            Reads: "",
        }]));
    };
    return (
        <table style={{ width: "100%", tableLayout: "fixed" }}>
            <thead>
                <tr>
                    <Cell>Gene1</Cell>
                    <Cell>Pos1</Cell>
                    <Cell>Gene2</Cell>
                    <Cell>Pos2</Cell>
                    <Cell>Reads</Cell>
                </tr>
            </thead>
            <tbody>
                {array.map((rowValue, index) => <FusionVariantItem
                    key={index}
                    rowIndex={index}
                    rowValue={rowValue}
                    onChange={handleInputChange}
                />)}
            </tbody>
            <tfoot>
                <tr>
                    <Cell colSpan={2} style={{ paddingTop: 10 }}>
                        <ButtonGroup size="xs">
                            <IconButton onClick={handleAdd} icon={<PlusIcon />} />
                            <IconButton onClick={handleMinus} icon={<MinusIcon />} />
                        </ButtonGroup>
                    </Cell>
                </tr>
            </tfoot>
        </table>
    );
};


/**
 * {
      "Drug": "",
      "Gene": "",
      "Variant_short": "",
      "Cancer_type": "",
      "Evidence": "",
      "Significance": "",
      "Article": {
        "text": "",
        "link": ""
      },
      "Rating": ""
    }
 */



const FusionTreatmentItem = ({ rowValue = {}, onChange, rowIndex }) => {
    const handleChange = name => value => {
        const next = { ...rowValue }
        set(next, name, value)
        onChange(rowIndex, next)
    }

    return (
        <tr>
            <Cell><Input value={rowValue.Drug} onChange={handleChange("Drug")} /></Cell>
            <Cell><Input value={rowValue.Gene} onChange={handleChange("Gene")} /></Cell>
            <Cell><Input value={rowValue.Variant_short} onChange={handleChange("Variant_short")} /></Cell>
            <Cell><Input value={rowValue.Cancer_type} onChange={handleChange("Cancer_type")} /></Cell>
            <Cell><Input value={rowValue.Evidence} onChange={handleChange("Evidence")} /></Cell>
            <Cell><Input value={rowValue.Significance} onChange={handleChange("Significance")} /></Cell>
            <Cell><Input value={rowValue.Article.text} onChange={handleChange("Article.text")} /></Cell>
            <Cell><Input value={rowValue.Article.link} onChange={handleChange("Article.link")} /></Cell>
            <Cell><Input value={rowValue.Rating} onChange={handleChange("Rating")} /></Cell>
        </tr>
    );
};


export function FusionTreatmentInputControl({ value = [], onChange }) {

    /**
     * Gene 
     * Variant_full
     * Region
     * Frequency
     * Effect
     * Coverage
     */

    const [array, setArray] = React.useState(value);
    const handleChange = next => {
        setArray(next);
        onChange(next);
    };
    const handleInputChange = (rowIndex, value) => {
        const next = [...array];
        next[rowIndex] = value;
        handleChange(next);
    };

    const handleMinus = () => {
        handleChange(array.slice(0, -1));
    };
    const handleAdd = () => {
        handleChange(array.concat([{
            "Drug": "",
            "Gene": "",
            "Variant_short": "",
            "Cancer_type": "",
            "Evidence": "",
            "Significance": "",
            "Article": {
              "text": "",
              "link": ""
            },
            "Rating": ""
          }]));
    };
    return (
        <table style={{ width: "100%", tableLayout: "fixed" }}>
            <thead>
                <tr>
                    <Cell>Drug</Cell>
                    <Cell>Gene</Cell>
                    <Cell>Variant_short</Cell>
                    <Cell>Cancer_type</Cell>
                    <Cell>Evidence</Cell>
                    <Cell>Significance</Cell>
                    <Cell>Article.text</Cell>
                    <Cell>Article.link</Cell>
                    <Cell>Rating</Cell>
                </tr>
            </thead>
            <tbody>
                {array.map((rowValue, index) => <FusionTreatmentItem
                    key={index}
                    rowIndex={index}
                    rowValue={rowValue}
                    onChange={handleInputChange}
                />)}
            </tbody>
            <tfoot>
                <tr>
                    <Cell colSpan={2} style={{ paddingTop: 10 }}>
                        <ButtonGroup size="xs">
                            <IconButton onClick={handleAdd} icon={<PlusIcon />} />
                            <IconButton onClick={handleMinus} icon={<MinusIcon />} />
                        </ButtonGroup>
                    </Cell>
                </tr>
            </tfoot>
        </table>
    );
};

