import React from "react";
import { List, FlexboxGrid } from 'rsuite';
import UserCircleIcon from '@rsuite/icons/legacy/UserCircleO';
import { useNavigate } from "react-router-dom";
import dataController from "../controller/info"
import WaitIcon from '@rsuite/icons/Wait';
import CheckOutlineIcon from '@rsuite/icons/CheckOutline';
import TimeIcon from '@rsuite/icons/Time';
import CloseOutlineIcon from '@rsuite/icons/CloseOutline';
import { Loader } from 'rsuite';


const styleRight = {
    display: 'flex',
    justifyContent: 'right',
    alignItems: 'center',
    height: '60px'
  };

const styleCenter = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '60px'
};

const slimText = {
  fontSize: '0.666em',
  color: '#97969B',
  fontWeight: 'lighter',
  paddingBottom: 5
};

const titleStyle = {
  paddingBottom: 5,
  whiteSpace: 'nowrap',
  fontWeight: 500
};

const dataStyle = {
  fontSize: '1.2em',
  fontWeight: 500
};


function getIcon(icon) {
    switch (icon) {
        case "queued": return <WaitIcon color="steelblue" fontSize='1.5em'/>
        case 'started': return <TimeIcon color="forestgreen" fontSize='1.5em'/>
        case 'done': return <CheckOutlineIcon color="forestgreen" fontSize='1.5em'/>
        case 'failed': return <CloseOutlineIcon color="tomato" fontSize='1.5em'/>
    }
}

import { useMediaQuery } from "../hooks/useMediaQuery"

export function ResultsList() {
    const navigate = useNavigate()
    const list = dataController.useList()
    const isMobile = !useMediaQuery('(min-width: 576px)')

    if (!list.length && dataController.loading) 
        return <div style={{ display: "grid", "justify-content": "center", "align-items": "center", height: "40vh" }}>
            <Loader size="lg" content="Загрузка..." />
        </div>

    function renderItem(item, index) {
        const msi = item?.msi?.percent
        const msiText = msi ? `${msi}%` : "NA"

        const onView = () => {
            navigate(`/results/${item.id}`)
        }

        const onlyPC = <>
                <FlexboxGrid.Item colspan={2} style={styleRight}>
                    <div style={{ textAlign: 'right' }}>
                        <div style={slimText}>Покрыто 30X</div>
                        <div style={dataStyle}>{Math.round(item.extra.qc['target30x'] * 100)}%</div>
                    </div>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={3} style={styleRight}>
                    <div style={{ textAlign: 'right' }}>
                        <div style={slimText}>Уникальных прочтений</div>
                        <div style={dataStyle}>{item.extra.qc['uniqueReads'].toLocaleString()}</div>
                    </div>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={2} style={styleRight}>
                    <div style={{ textAlign: 'right' }}>
                        <div style={slimText}>Коэффициент обогащения</div>
                        <div style={dataStyle}>{Math.round(item.extra.qc['foldEnrichment'])}x</div>
                    </div>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={2} style={styleRight}>
                    <div style={{ textAlign: 'right' }}>
                    <div style={slimText}>Медианное покрытие</div>
                    <div style={dataStyle}>{Math.round(item.extra.qc['medianCoverage'])}x</div>
                    </div>
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={1} style={styleRight}>
                    <div style={{ textAlign: 'right' }}>
                    <div style={slimText}>MSI</div>
                    <div style={dataStyle}>{msiText}</div>
                    </div>
                </FlexboxGrid.Item>
        </>

        return <List.Item key={item['id']} index={index + 1}>
            <FlexboxGrid>
                <FlexboxGrid.Item colspan={isMobile ? 3: 2} style={styleCenter}>
                    {getIcon(item['status'])}
                </FlexboxGrid.Item>
                <FlexboxGrid.Item colspan={isMobile ? 17: 9} style={{ ...styleCenter, flexDirection: 'column', alignItems: 'flex-start', overflow: 'hidden' }}>
                    <div style={titleStyle}>{item['name']}</div>
                    <div style={slimText}>
                        <div>
                            <UserCircleIcon />
                            {' ' + item['user']}
                        </div>
                        <div>{item['created_at']}</div>
                    </div>
                </FlexboxGrid.Item>
                {isMobile ? null : onlyPC}
                <FlexboxGrid.Item colspan={2} style={styleCenter}>
                    <a onClick={onView}>Open</a>
                    {/** <span style={{ padding: 5 }}>|</span>
                         <a href="#">Edit</a> */}
                </FlexboxGrid.Item>
            </FlexboxGrid>
        </List.Item>
  }

  return <List hover>
      {list.map(renderItem)}
  </List>
};
