import React from "react"
import { Table } from 'rsuite';
import controller from "../controller/info"


export function SourceData(){
  const [sortColumn, setSortColumn] = React.useState();
  const [sortType, setSortType] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const data = controller.useSourceList()

  const getData = () => {
    if (sortColumn && sortType) {
      return data.sort((a, b) => {
        let x = a[sortColumn];
        let y = b[sortColumn];
        if (typeof x === 'string') {
          x = x.charCodeAt();
        }
        if (typeof y === 'string') {
          y = y.charCodeAt();
        }
        if (sortType === 'asc') {
          return x - y;
        } else {
          return y - x;
        }
      });
    }
    return data;
  };

  const handleSortColumn = (sortColumn, sortType) => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setSortColumn(sortColumn);
      setSortType(sortType);
    }, 200);
  };

  console.log(getData())

  return (
    <Table
      height={420}
      data={getData()}
      sortColumn={sortColumn}
      sortType={sortType}
      onSortColumn={handleSortColumn}
      loading={loading}
    >
      <Table.Column minWidth={230} flexGrow={2} align="left" fixed sortable>
        <Table.HeaderCell>Название</Table.HeaderCell>
        <Table.Cell dataKey="extra.fullname" />
      </Table.Column>

      <Table.Column minWidth={130} flexGrow={1} sortable>
        <Table.HeaderCell>ID Образца</Table.HeaderCell>
        <Table.Cell dataKey="extra.sample_id" />
      </Table.Column>

      <Table.Column minWidth={130} sortable>
        <Table.HeaderCell>Секвенатор</Table.HeaderCell>
        <Table.Cell dataKey="extra.seq_platform" />
      </Table.Column>

      <Table.Column minWidth={100} sortable>
        <Table.HeaderCell>ID проточной ячейки</Table.HeaderCell>
        <Table.Cell dataKey="extra.flow_id" />
      </Table.Column>

      <Table.Column minWidth={100} sortable>
        <Table.HeaderCell>Дорожка</Table.HeaderCell>
        <Table.Cell dataKey="extra.cell_number" />
      </Table.Column>

      <Table.Column minWidth={100} sortable>
        <Table.HeaderCell>Баркод</Table.HeaderCell>
        <Table.Cell dataKey="extra.barcode" />
      </Table.Column>
    </Table>
  );
};