import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import dataController from "../controller/info"
import { Grid, Row, Col, Modal } from 'rsuite';
import { Panel, PanelGroup, FlexboxGrid, List } from 'rsuite';
import { Button, ButtonGroup, ButtonToolbar } from 'rsuite';
import { Running } from "./ProgressInfo"
import { Loader } from 'rsuite';
import RemindIcon from '@rsuite/icons/legacy/Remind';

function download(href, filename) {
  const link = document.createElement('a')

  link.setAttribute('href', href)
  link.setAttribute('download', filename)
  link.style.display = 'none'

  document.body.appendChild(link)

  link.click()

  document.body.removeChild(link)
}



function LeftMenu({ data, id }) {
  const navigate = useNavigate()

  const [open, setOpen] = useState(false);
  const openDeleteDialog = () => setOpen(true);
  const closeDeleteDialog = () => setOpen(false);

  const enablePreQC = data.extra.complete ? data.extra.complete.preqc : false
  const enablePostQC = data.extra.complete ? data.extra.complete.postqc : false
  const enableAlignment = data.extra.complete ? data.extra.complete.alignment : false
  const enableVariants = data.extra.complete ? data.extra.complete.calling : false
  const enablePanels = data.extra.complete ? data.extra.complete.panels : false
  const enableFusions = data.extra.complete ? data.extra.complete.star : false
  const enableMSI = data.extra.complete ? data.extra.complete.msi : false

  const onDownloadVCF = () => download(`/api/results/${id}/output.vcf.gz`, `${data.name}.vcf.gz`)
  const onDownloadPanels = () => download(`/api/results/${id}/panels.xlsx`, `${data.name}.xlsx`)
  const onDownloadGVCF = () => download(`/api/results/${id}/output.g.vcf.gz`, `${data.name}.g.vcf.gz`)

  const onDownloadFusions = () => download(`/api/results/${id}/fusions.tsv`, `${data.name}.fusions.tsv`)
  const onDownloadDiscardedFusions = () => download(`/api/results/${id}/fusions.discarded.tsv`, `${data.name}.fusions.discarded.tsv`)

  const onDownloadVardictVCF = () => download(`/api/results/${id}/vardict.vcf`, `${data.name}.vardict.vcf`)

  const onDownloadVariants = () => download(`/api/results/${id}/variants.xlsx`, `${data.name}.variants.xlsx`)
  const onDownloadMSI = () => download(`/api/results/${id}/msi.json`, `${data.name}.msi.json`)
  
  
  
  const onBack = () => navigate(`/results`)
  const onPreQC = () => navigate(`/results/${id}/preqc`)
  const onPostQC = () => navigate(`/results/${id}/postqc`)
  const onIGV = () => {
    if (enableVariants) {
      navigate(`/results/${id}/igv`)
    } else {
      navigate(`/results/${id}/igv?novcf=true`)
    }
  }

  async function deleteTask() {
    try {
      await fetch(`/api/tasks/${id}/delete`)
      await dataController.fetch()
      navigate("/results")
    } catch {
      alert("Ошибка при удалении задачи")
    }
  }

  return <Panel bodyFill style={{ marginTop: 20 }}>
    <ButtonToolbar>

      <ButtonGroup vertical block style={{ width: "100%" }}>
        <Button block onClick={onBack}>Назад к списку задач</Button>
      </ButtonGroup>

      <ButtonGroup vertical block style={{ width: "100%" }}>
        <Button appearance="ghost" block onClick={onPreQC} disabled={!enablePreQC}>QC исходных данных</Button>
        <Button appearance="ghost" block onClick={onPostQC} disabled={!enablePostQC}>QC выравнивания</Button>
        <Button appearance="ghost" block onClick={onIGV} disabled={!enableAlignment}>Открыть IGV</Button>
      </ButtonGroup>

      <ButtonGroup vertical block style={{ width: "100%" }}>
        <Button appearance="primary" block onClick={onDownloadVCF} disabled={!enableVariants}>Скачать VCF</Button>
        <Button appearance="primary" block onClick={onDownloadGVCF} disabled={!enableVariants}>Скачать gVCF</Button>
        <Button appearance="primary" block onClick={onDownloadVardictVCF} disabled={!enableVariants}>Скачать Vardict VCF</Button>
        <Button appearance="primary" block onClick={onDownloadVariants} disabled={!enableVariants}>Скачать варианты</Button>
        <Button appearance="primary" block onClick={onDownloadPanels} disabled={!enablePanels}>Скачать панели</Button>
        <Button appearance="primary" block onClick={onDownloadFusions} disabled={!enableFusions}>Fusions</Button>
        <Button appearance="primary" block onClick={onDownloadDiscardedFusions} disabled={!enableFusions}>Discarded Fusions</Button>
        <Button appearance="primary" block onClick={onDownloadMSI} disabled={!enableMSI}>MSI</Button>
      </ButtonGroup>

      <ButtonGroup vertical block style={{ width: "100%" }}>
        <Button color="red" appearance="primary" onClick={openDeleteDialog} block>Удалить</Button>
      </ButtonGroup>
    </ButtonToolbar>

    <Modal backdrop="static" style={{ maxWidth: "100vw" }} size="xs" role="alertdialog" open={open} onClose={closeDeleteDialog}>
        <Modal.Body>
          <RemindIcon style={{ color: '#ffb300', fontSize: 24 }} />
          Удалить задачу? Удаление происходит вместе с данными!
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteTask} appearance="primary">
            Удалить
          </Button>
          <Button onClick={closeDeleteDialog} appearance="subtle">
            Отмена
          </Button>
        </Modal.Footer>
    </Modal>
  </Panel>
}


function getStatusName(status) {
  switch (status) {
    case "done": return "Завершено"
    case "started": return "Запущено"
    case "failed": return "Провалено"
    case "queued": return "Ожидает очереди"
    default: return "FIX ME"
  }
}

function Content({ id, data }) {
  const { name, extra, created_at, started_at, finished_at, input } = data
  const intervals_id = input ? input.intervals_id : "Unknown"
  const interval = dataController.useIntervalById(intervals_id) || { name: "Unknown" }
  const status = getStatusName(data.status)
  const { qc } = extra

  function viewDate(raw) {
    const date = new Date(raw)
    if (!date) return "NA"
    console.log(date)
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`
  }

  const isQCPassed = qc.foldEnrichment >= 35 && qc.target30x > 0.85 && qc.medianCoverage >= 30

  return <Grid fluid>
    <Row>
      <Col md={10}><b>Название образца:</b></Col>
      <Col md={14}>{name}</Col>
    </Row>
    <Row>
      <Col md={10}><b>BED:</b></Col>
      <Col md={14}>{interval.name}</Col>
    </Row>
    <Row>
      <Col md={10}><b>Статус:</b></Col>
      <Col md={14}>{status}</Col>
    </Row>
    <Row>
      <Col md={10}><b>Уникальных прочтений:</b></Col>
      <Col md={14}>{qc.uniqueReads}</Col>
    </Row>
    <Row>
      <Col md={10}><b>Время начала анализа:</b></Col>
      <Col md={14}>{viewDate(started_at)}</Col>
    </Row>
    <Row>
      <Col md={10}><b>Время окончания анализа:</b></Col>
      <Col md={14}>{viewDate(finished_at)}</Col>
    </Row>
    <Row>
      <Col md={10}><b>Выровнено на референс:</b></Col>
      <Col md={14}>{Math.round(qc.target30x*100)}%</Col>
    </Row>
    <Row>
      <Col md={10}><b>Медианное покрытие:</b></Col>
      <Col md={14}>{Math.round(qc.medianCoverage)}x</Col>
    </Row>
    <Row>
      <Col md={10}><b>Коэффициент целевого обогащения:</b></Col>
      <Col md={14}>{Math.round(qc.foldEnrichment)}x</Col>
    </Row>
    <Row>
      <Col md={10}><b>Пройдены все метрики качества:</b></Col>
      <Col md={14}>{isQCPassed ? "Да" : "Нет"}</Col>
    </Row>
  </Grid>
}

export function ResultInfo() {
  const { id } = useParams()
  const data = dataController.useTaskById(id)

  if (!data && dataController.loading)
    return <div style={{ display: "grid", "justify-content": "center", "align-items": "center", height: "40vh" }}>
      <Loader size="lg" content="Загрузка..." />
    </div>

  if (!data && !dataController.loading)
    return <div>Not found</div>

  const progressPanel = (data.extra && data.extra.progress) ? <Panel header="Подзадачи" eventKey={1} id="panel2">
    <Running data={data.extra.progress} />
  </Panel> : null


  const content = <PanelGroup accordion defaultActiveKey={progressPanel ? 1 : 2}>
    {progressPanel}
    <Panel header="Информация" eventKey={2} id="panel1">
      <Content data={data} id={id}/>
    </Panel>
    <Panel header="Отладочная информация" eventKey={2} id="panel2">
      <pre>{JSON.stringify(data, null, 4)}</pre>
    </Panel>
  </PanelGroup>


  return <Grid>
    <Row gutter={0}>
      <Col sm={24} md={7}><LeftMenu id={id} data={data}/></Col>
      <Col sm={0} md={1}></Col>
      <Col sm={24} md={16}>
        {content}
      </Col>
    </Row>
  </Grid>
}


