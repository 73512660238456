import React from "react"
import 'rsuite/dist/rsuite.min.css';
import { useParams } from "react-router-dom";

export function MultiQC({ type }) {
    const { id } = useParams()

    const src = `/api/results/${id}/${type}.html`

    return <iframe src={src}
        name="targetframe"
        allowTransparency="true"
        width="100%"
        style={{ display: "flex", left: 0, right: 0, bottom: 0, top: 0, width: "100%", height: "calc(100vh - 120px)" }}
        frameborder="0"
    />
}