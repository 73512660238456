import React, { useEffect, useState } from "react";
import EventEmitter from 'eventemitter3';

class DataController extends EventEmitter {
    loggedIn = false
    current = []
    currentSources = []
    currentIntervals = []

    loading = true

    useList() {
        const [ list, setList ] = useState(this.current)
        
        useEffect(() => {
            this.on("update", setList)
            return () => {
                this.off("update", setList)
            }
        }, [])

        return list
    }


    useSourceList() {
        const [ list, setList ] = useState(this.currentSources)
        
        useEffect(() => {
            this.on("update:sources", setList)
            return () => {
                this.off("update:sources", setList)
            }
        }, [])

        return list
    }

    useIntervalsList() {
        const [ list, setList ] = useState(this.currentIntervals)
        
        useEffect(() => {
            this.on("update:intervals", setList)
            return () => {
                this.off("update:intervals", setList)
            }
        }, [])

        return list
    }


    useIntervalById(id) {
        const [ item, setItem ] = useState(this.getInterval(id))

        useEffect(() => {
            const refresh = () => {
                setItem(this.getInterval(id))
            }
            this.on("update:intervals", refresh)
            return () => {
                this.off("update:intervals", refresh)
            }
        }, [])

        return item
    }

    useTaskById(id) {
        const [ item, setItem ] = useState(this.getTask(id))

        useEffect(() => {
            const refresh = () => {
                setItem(this.getTask(id))
            }
            this.on("update", refresh)
            return () => {
                this.off("update", refresh)
            }
        }, [])

        return item
    }

    async fetch() {
        try {
            this.loading = true
            const response = await fetch(`/api/tasks`)
            const data = await response.json()
            this.current = data
            this.loading = false
            this.emit("update", data)
        } catch (error) {
            console.log(error)
        }
    }

    async fetchSources() {
        try {
            const response = await fetch(`/api/sources`)
            const data = await response.json()
            this.currentSources = data
            this.emit("update:sources", data)
        } catch (error) {
            console.log(error)
        }
    }

    async fetchIntervals() {
        try {
            const response = await fetch(`/api/intervals`)
            const data = await response.json()
            this.currentIntervals = data
            this.emit("update:intervals", data)
        } catch (error) {
            console.log(error)
        }
    }

    async login(email, password) {
        if (this.loggedIn) return
        this.loggedIn = true
        this.fetch()
        this.fetchSources()
        this.fetchIntervals()

        setInterval(() => { 
            this.fetch()
        }, 5000)
    }

    getTask(id) {
        return this.current.find(item => {
            return item.id == id
        })
    }

    getInterval(id) {
        return this.currentIntervals.find(item => {
            return item.id == id
        })
    }

}


export default new DataController()