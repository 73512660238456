import React from "react";

import { Panel, PanelGroup, Placeholder } from 'rsuite';

export function HomeScreen() {
    return  <PanelGroup>
    <Panel header="Экзом.ру 3000">
        <p>
        Приложение Экзом.ру представляет собой интегрированное программное решение для анализа геномных данных, 
        которое охватывает все этапы от первичных необработанных данных до идентификации, аннотации и клинической 
        интерпретации геномных вариантов, представленных в формате удобной таблицы с ранжированием по уровню 
        патогенности и классификацией обнаруженных вариантов по виртуальным генным панелям.
        </p>
    </Panel>
    <Panel header="Основные стадии анализа и функциональные возможности включают следующие элементы:">
    <ol>
        <li>Входной контроль качества чтений и оценка контаминации: процесс оценки и контроля качества исходных данных для анализа.</li>
        <li>Выравнивание первичных чтений на референсный геном и последующая обработка выровненных данных.</li>
        <li>Контроль качества выровненных данных, включающий базовые показатели качества для анализа экзома: медианное покрытие целевых регионов, коэффициент обогащения целевых регионов и доля целевых регионов с покрытием не менее 30X.</li>
        <li>Определение отличий от референса (variant calling) и генотипирование образца.</li>
        <li>Аннотация и клиническая интерпретация обнаруженных геномных вариантов.</li>
        <li>Создание ранжированной (по вероятности патогенности) таблицы геномных вариаций, классифицированных по виртуальным генным панелям в формате, удобном для анализа медицинским генетиком (*xlsx). Посмотреть пример готовых данных для генетика можно по <a href = "https://exome.ru/test/BRCA-test-WES.xlsx">ссылке</a>.</li>
        <li>Возможность интерактивного просмотра информации о покрытии каждого анализируемого варианта во встроенной веб-версии геномного браузера IGV.</li>
        <li>Возможность управлять задачами и просматривать данные анализа с мобильного устройства.</li>
    </ol>
    </Panel>
    <Panel header="В процессе разработки геномного анализатора Экзом.ру были использованы следующие открытые программные решения для обеспечения эффективности и качества работы:">
    <ol>
        <li>FastQC &mdash; инструмент для входного контроля качества исходных данных секвенирования.</li>
        <li>Kraken2 &mdash; система быстрой и точной классификации метагеномных последовательностей для оценки загрязнения образца.</li>
        <li>BBTools &mdash; набор инструментов для обработки и анализа биоинформационных данных.</li>
        <li>BWA&mdash;mem2 &mdash; программное обеспечение для выравнивания геномных чтений на референсный геном.</li>
        <li>Blast &mdash; инструмент для сравнения биологических последовательностей.</li>
        <li>VEP &mdash; инструмент для аннотации геномных вариаций.</li>
        <li>DeepVariant &mdash; один из наиболее точных инструментов для идентификации геномных вариаций и генотипирования данных экзомного секвенирования.</li>
        <li>Собственные программные инструменты Экзом.ру.</li>
    </ol>
    </Panel>
    <Panel style={{ marginBottom: 100 }}>
        Разрабатываемое программное обеспечение обладает модульной структурой и может быть расширено в соответствии с потребностями заказчика.
    Почта для обратной связи: <a href = "mailto: info@exome.ru">info@exome.ru</a>
    </Panel>
  </PanelGroup>
}