
import { BrowserRouter, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import React, { useEffect, useRef } from "react"
import igv from "igv/dist/igv.esm"
//import { useMediaQuery } from "../hooks/useMediaQuery"
import controller from "../controller/info"

function debounce(func, wait, immediate) {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};


export function IGV() {
  const { id } = useParams()
  const task = controller.useTaskById(id)
  if (!task) return "Loading..."
  return <IGVView task={task} id={id}/>
}

function IGVView({ task, id }) {
    //const isMobile = !useMediaQuery('(min-width: 576px)')
    const [searchParams, setSearchParams] = useSearchParams();
    const igvInstance = useRef({})
    const skipVCFFile = searchParams.get("novcf") || false

    const intervals_id = task.input.intervals_id || "default"

    if (!igvInstance.current.div) {
      igvInstance.current.div = document.createElement("div")
    }
    if (!igvInstance.current.setSearchParams) {
      igvInstance.current.setSearchParams = debounce(setSearchParams, 1000, false)
    }
  
    useEffect(() => {
      const locus = searchParams.get("locus") || "1:248,801,198-248,802,207"
      const tracks = [
        {
          type: "alignment",
          format: "bam",
          name: "Alignment",
          url: `/api/results/${id}/sorted_mrkdup_bqsr.bam`,
          indexURL: `/api/results/${id}/sorted_mrkdup_bqsr.bai`,
          alignmentRowHeight: 8,
          visibilityWindow: 30000
        },
        {
          type: "annotation",
          format: "bed",
          name: "BED",
          url: `/api/reference/intervals/${intervals_id}/regions.bed`,
          visibilityWindow: 3000000
        }
      ]

      if (!skipVCFFile) {
        tracks.push({
          type: "variant",
          format: "vcf",
          url: `/api/results/${id}/output.vcf.gz`,
          indexURL: `/api/results/${id}/output.vcf.gz.tbi`,
          name: "Variants",
          squishedCallHeight: 1,
          expandedCallHeight: 4,
          visibilityWindow: 30000
        })
      }

      const browser = igv.createBrowser(igvInstance.current.div, {
        reference: {
          "id": "hs37d5",
          "name": "Human (GRCh38/hg38)",
          "fastaURL": "/api/reference/hs37d5.fa",
          "indexURL": "/api/reference/hs37d5.fa.fai",
        },
        locus,
        tracks
      })
      browser.then(browser => {
        browser.on("locuschange", function (data) {
          try {
            const locusSearchString = data[0].getLocusString().replaceAll(",", "")
            igvInstance.current.setSearchParams({ locus: locusSearchString })
          } catch {
            console.log("Warning")
          }
        })
      })
    }, [])
  
    return <div key="igv" ref={element => {
      if (!element) return
      element.appendChild(igvInstance.current.div)
    }}/>
  }