export const cancerTypePickerData = [
  {
    value: "Any",
    label: "Тип рака не установлен"
  },
  {
    value: "Adrenal Gland",
    label: "Рак надпочечников"
  },
  {
    value: "Ampulla of Vater",
    label: "Рак фатерова сосочка"
  },
  {
    value: "Biliary Tract",
    label: "Рак желчных протоков"
  },
  {
    value: "Рак мочевого пузыря/мочевыводящих путей",
    label: ""
  },
  {
    value: "Bone",
    label: "Остеосаркома"
  },
  {
    value: "Breast",
    label: "Рак молочной железы"
  },
  {
    value: "Cervix",
    label: "Рак шейки матки"
  },
  {
    value: "CNS/Brain",
    label: "Опухоль ЦНС/головного мозга"
  },
  {
    value: "Colon/Rectum",
    label: "Колоректальный рак"
  },
  {
    value: "Esophagus/Stomach",
    label: "Рак пищевода/желудка"
  },
  {
    value: "Eye",
    label: "Рак глаза"
  },
  {
    value: "Head and Neck",
    label: "Рак головы и шеи"
  },
  {
    value: "Kidney",
    label: "Рак почки"
  },
  {
    value: "Liver",
    label: "Рак печени"
  },
  {
    value: "Lung",
    label: "Рак легкого"
  },
  {
    value: "Lymphoid",
    label: "Лимфома"
  },
  {
    value: "Myeloid",
    label: "Миелоидный лейкоз"
  },
  {
    value: "Ovary/Fallopian Tube",
    label: "Рак яичников/маточных труб"
  },
  {
    value: "Pancreas",
    label: "Рак поджелудочной железы"
  },
  {
    value: "Peripheral Nervous System",
    label: "Рак периферической нервной системы"
  },
  {
    value: "Peritoneum",
    label: "Рак брюшины"
  },
  {
    value: "Pleura",
    label: "Рак плевры"
  },
  {
    value: "Prostate",
    label: "Рак предстательной железы"
  },
  {
    value: "Skin",
    label: "Меланома"
  },
  {
    value: "Soft Tissue",
    label: "Саркома мягких тканей"
  },
  {
    value: "Testis",
    label: "Рак яичка"
  },
  {
    value: "Thymus",
    label: "Рак тимуса"
  },
  {
    value: "Thyroid",
    label: "Рак щитовидной железы"
  },
  {
    value: "Uterus",
    label: "Рак матки"
  },
  {
    value: "Vulva/Vagina",
    label: "Рак вульвы"
  }
];
