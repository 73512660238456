import { Button, Nav, ButtonGroup, ButtonToolbar, Form, Message } from 'rsuite';

import { Panel, FlexboxGrid } from 'rsuite';

import React, { useState, useEffect, useRef } from "react"
import 'rsuite/dist/rsuite.min.css';
import HomeIcon from '@rsuite/icons/legacy/Home';

import Container from 'rsuite/Container';
import Header from 'rsuite/Header';
import Content from 'rsuite/Content';
import Footer from 'rsuite/Footer';
import Sidebar from 'rsuite/Sidebar';
import Input from 'rsuite/Input';
import Uploader from 'rsuite/Uploader';
import CheckboxGroup from 'rsuite/CheckboxGroup';

import RadioGroup from 'rsuite/RadioGroup';
import Checkbox from 'rsuite/Checkbox';
import DatePicker from 'rsuite/DatePicker';
import CheckPicker from 'rsuite/CheckPicker';
import SelectPicker from 'rsuite/SelectPicker';
import TagPicker from 'rsuite/TagPicker';
import InputPicker from 'rsuite/InputPicker';
import MultiCascader from 'rsuite/MultiCascader';
import InputNumber from 'rsuite/InputNumber';
import Cascader from 'rsuite/Cascader';

import { SourceData } from "./screens/SourceData"
import { HomeScreen } from "./screens/HomeScreen"
import { Helmet } from "react-helmet";
import { IGV } from "./screens/IGV"
import ky from "ky"

import { download } from "./utils/download"

import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Outlet,
  Link,
  useLocation,
  useNavigate,
  useParams
} from "react-router-dom";

import dataController from "./controller/info"

import { ResultsList } from "./screens/ResultsList"
import { ResultInfo } from "./screens/ResultInfo"
import { MultiQC } from "./screens/MultiQC"
import { CreateTaskScreen } from "./screens/CreateTaskScreen"
import { cancerTypePickerData } from './cancerTypePickerData';

import { GerminalInputControl, GerminalTreatmentInputControl } from "./GerminalForm"
import { FusionInputControl, FusionTreatmentInputControl } from "./FusionForm"

const TextArea = React.forwardRef((props, ref) => (
  <Input {...props} as="textarea" ref={ref} />
));


const MyCascader = React.forwardRef((props, ref) => (
  <Cascader {...props} columnWidth={300} placement="topStart"  block ref={ref} />
));

/**
 * HACK TO WORKAROUND https://github.com/facebook/react/issues/11538#issuecomment-417504600
 */
if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild;
  Node.prototype.removeChild = function (child) {
    if (child.parentNode !== this) {
      if (console) {
        console.error('Cannot remove a child from a different parent', child, this);
      }
      return child;
    }
    return originalRemoveChild.apply(this, arguments);
  }

  const originalInsertBefore = Node.prototype.insertBefore;
  Node.prototype.insertBefore = function (newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error('Cannot insert before a reference node from a different parent', referenceNode, this);
      }
      return newNode;
    }
    return originalInsertBefore.apply(this, arguments);
  }
}



function Navbar({ active, onSelect, ...props }) {
  return <Nav {...props} activeKey={active} onSelect={onSelect} style={{ marginBottom: 0 }}>
    <Nav.Item eventKey="home" icon={<HomeIcon />}>Главная</Nav.Item>
    <Nav.Item eventKey="source">Данные</Nav.Item>
    <Nav.Item eventKey="create">Создать</Nav.Item>
    <Nav.Item eventKey="results">Задачи</Nav.Item>
    <Nav.Item eventKey="onco-report">Онко-отчет</Nav.Item>
    <Nav.Item eventKey="tapes-report">Tapes-отчет</Nav.Item>
    <Nav.Item eventKey="add-sample">Add sample</Nav.Item>
  </Nav>
}


function getDateAsString(dateIn) {
  var yyyy = dateIn.getFullYear();
  var mm = dateIn.getMonth() + 1; // getMonth() is zero-based
  var dd = dateIn.getDate();

  return `${dd}.${mm}.${yyyy}`
}




function AddSampleToDb() {

  const [data, setData] = useState([])
  
  useEffect(() => {
    (async function () {
      const result = await fetch("/api/info/files")
      const json = await result.json()
      setData(json)
    })()
  }, [])



  const [formValue, setFormValue] = React.useState({
    fastq_f: "",
    fastq_r: "",
    fullname: "ZD2A0109-GED-E080RHE",
    seq_platform: "MGI",
    flow_id: "350181180",
    cell_number: "1",
    barcode: "94M",
    sample_id: "ZD2A0109"
  });

  console.log(formValue)

  async function handleSubmit() {
    const {
      fastq_f,
      fastq_r,
      ...extra
    } = formValue

    const response = await ky.post("/api/add-sample-to-db", {
      body: JSON.stringify({ extra, fastq: [fastq_f, fastq_r] }),
      headers: {
        'content-type': 'application/json'
      }
    }).json()

    if (response && response.id) {
      alert(`Sample ${response.id} added to database`)
    }
  }

  return <Panel>
    <Form
      fluid
      layout="horizontal"
      formValue={formValue}
      onChange={formValue => setFormValue(formValue)}
      onSubmit={handleSubmit}
    >

      <Form.Group controlId="sample_id">
        <Form.ControlLabel>sample_id:</Form.ControlLabel>
        <Form.Control name="sample_id" />
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>

      <Form.Group controlId="barcode">
        <Form.ControlLabel>barcode:</Form.ControlLabel>
        <Form.Control name="barcode" />
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>

      <Form.Group controlId="cell_number">
        <Form.ControlLabel>cell_number:</Form.ControlLabel>
        <Form.Control name="cell_number" />
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>

      <Form.Group controlId="flow_id">
        <Form.ControlLabel>flow_id:</Form.ControlLabel>
        <Form.Control name="flow_id" />
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>

      <Form.Group controlId="seq_platform">
        <Form.ControlLabel>seq_platform:</Form.ControlLabel>
        <Form.Control name="seq_platform" />
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>

      <Form.Group controlId="fullname">
        <Form.ControlLabel>fullname:</Form.ControlLabel>
        <Form.Control name="fullname" />
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>

      <Form.Group controlId="fastq_f">
        <Form.ControlLabel>fastq_f:</Form.ControlLabel>
        <Form.Control name="fastq_f" />
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>

      <Form.Group controlId="fastq_r">
        <Form.ControlLabel>fastq_r:</Form.ControlLabel>
        <Form.Control name="fastq_r" />
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>

      <Form.Group controlId="fastq_f">
        <Form.ControlLabel>fastq_f:</Form.ControlLabel>
        <Form.Control name="fastq_f" accepter={MyCascader} data={data}/>
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>

      <Form.Group controlId="fastq_r">
        <Form.ControlLabel>fastq_r:</Form.ControlLabel>
        <Form.Control name="fastq_r" accepter={MyCascader} data={data}/>
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>

      <Form.Group>
        <ButtonToolbar>
          <Button appearance="primary" type='submit'>Submit</Button>
        </ButtonToolbar>
      </Form.Group>





    </Form>
  </Panel>
}


/** TAPES */



function TapesReportLog() {
  const { id } = useParams()
  const [data, setData] = React.useState({ text: "Loading...", exist: false, filename: "tapes.xlsx" })

  React.useEffect(function () {
    const interval = setInterval(async function () {
      const data = await ky.get(`/api/tapes-report/${id}`).json()
      setData(data)
    }, 2000)
    return function () {
      clearInterval(interval)
    }
  }, [])

  return <>
    {data.exist ? <a href={`/api/tapes-report/${id}/tapes.xlsx`} download={data.filename}>Download</a> : <p>File not ready</p>}
    <pre>{data.log}</pre>
  </>
}


function TapesReport() {
  const navigate = useNavigate()
  const [formValue, setFormValue] = React.useState({});

  async function handleSubmit() {
    const { file } = formValue
    if (!file || file.length === 0) {
      return alert("File is not selected")
    }
    if (file && file.length !== 1) {
      return alert("Only one file should be selected")
    }

    const formData = new FormData()

    formData.append("input", file[0].blobFile)

    const response = await ky.post("/api/create-tapes-report", { body: formData }).json()

    if (response && response.run_id) {
      navigate(`/tapes-reports/${response.run_id}`)
    }
  }

  return <Panel>
    <Form
      fluid
      layout="horizontal"
      formValue={formValue}
      onChange={formValue => setFormValue(formValue)}
      onSubmit={handleSubmit}
    >
      <Form.Group controlId="file">
        <Form.ControlLabel>Uploader:</Form.ControlLabel>
        <Form.Control name="file" autoUpload={false} multiple={false} accepter={Uploader} />
      </Form.Group>
      <Form.Group>
        <ButtonToolbar>
          <Button appearance="primary" type='submit'>Submit</Button>
        </ButtonToolbar>
      </Form.Group>
    </Form>
  </Panel>
}




/** ONKO */


function OncoReportLog() {
  const { id } = useParams()
  const [data, setData] = React.useState({ text: "Loading...", exist: false, filename: "report.pdf" })

  React.useEffect(function () {
    const interval = setInterval(async function () {
      const data = await ky.get(`/api/onco-report/${id}`).json()
      setData(data)
    }, 10000)
    return function () {
      clearInterval(interval)
    }
  }, [])

  return <>
    {data.exist ? <a href={`/api/onco-report/${id}/pdf`} download={data.filename}>Download</a> : <p>File not ready</p>}
    <pre>{data.log}</pre>
  </>
}


function OncoReport() {
  const navigate = useNavigate()
  const [formValue, setFormValue] = React.useState({
    sample_id: "",
    fullname: "",
    dob: new Date(),
    reportdate: new Date(),
    sampletype: "FFPE",
    tumor_type: "Any",
    coverageNormal: "100x",
    coverageTumor: "400x",
    TMB: "TMB (мутационная нагрузка опухоли) - высокий уровень",
    MSS: "MSS (Микросателлитная стабильность) - стабильный",
    MSS_verdict: "микросателлитной нестабильности не выявлено",
    tumor_af_min: 0.02,
    tumor_dp_min: 10,
    style: "fhm",
    control_dp_min: 0,
    control_af_max: 1.0,
    genomic_variant: [],
    genomic_treatment: [],
    preprocessing: "disabled",
    methodname: "WES",
    platformname: "MGISEQ-2000",
    targetCoverage: "100%",
    targetSize: "32Mb",
    targetVariants: "20K",
    static1: "",
    static2: "",
    static3: "",
  });

  async function handleSubmit() {
    const {
      dob, reportdate, file, tumor_type, sample_id,
      tumor_af_min, tumor_dp_min,
      control_dp_min, control_af_max,
      preprocessing,
      style,
      genomic_variant,
      genomic_treatment,
      fusion_variant,
      fusion_treatment,
      ...metadata
    } = formValue

    console.log(formValue)

    metadata.dob = getDateAsString(dob)
    metadata.reportdate = getDateAsString(reportdate)
    metadata.germinal = { genomic_variant, treatment_option: genomic_treatment }
    metadata.fusions = { fusion_variant, fusion_treatment }

    const config = {
      tumor_af_min, tumor_dp_min,
      control_dp_min, control_af_max,
      preprocessing,
      style,
      sample_id, tumor_type, TVAF: "TVAF", TDP: "TDP", metadata
    }

    console.log(file)

    if (!file || file.length === 0) {
      return alert("File is not selected")
    }

    if (file && file.length !== 1) {
      return alert("Only one file should be selected")
    }

    const formData = new FormData()

    formData.append("config", JSON.stringify(config))
    formData.append("input", file[0].blobFile)

    const response = await ky.post("/api/create-onco-report", { body: formData }).json()

    if (response && response.run_id) {
      navigate(`/onco-reports/${response.run_id}`)
    }
  }

  return <Panel>
    <Form
      fluid
      layout="horizontal"
      formValue={formValue}
      onChange={formValue => setFormValue(formValue)}
      onSubmit={handleSubmit}
    >

      <Form.Group controlId="sample_id">
        <Form.ControlLabel>sample_id:</Form.ControlLabel>
        <Form.Control name="sample_id" />
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>

      <Form.Group controlId="fullname">
        <Form.ControlLabel>ФИО:</Form.ControlLabel>
        <Form.Control name="fullname" />
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>


      <Form.Group controlId="sampletype">
        <Form.ControlLabel>sampletype:</Form.ControlLabel>
        <Form.Control name="sampletype" />
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>







      <Form.Group controlId="methodname">
        <Form.ControlLabel>methodname:</Form.ControlLabel>
        <Form.Control name="methodname" />
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>

      <Form.Group controlId="platformname">
        <Form.ControlLabel>platformname:</Form.ControlLabel>
        <Form.Control name="platformname" />
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>

      <Form.Group controlId="targetCoverage">
        <Form.ControlLabel>targetCoverage:</Form.ControlLabel>
        <Form.Control name="targetCoverage" />
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>

      <Form.Group controlId="targetSize">
        <Form.ControlLabel>targetSize:</Form.ControlLabel>
        <Form.Control name="targetSize" />
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>      
      
      <Form.Group controlId="targetVariants">
        <Form.ControlLabel>targetVariants:</Form.ControlLabel>
        <Form.Control name="targetVariants" />
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>







      <Form.Group controlId="staticintroduction">
        <Form.ControlLabel>staticintroduction:</Form.ControlLabel>
        <Form.Control accepter={TextArea} placeholder={"Default static will be used"} name="staticintroduction" />
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>

      <Form.Group controlId="staticmethods">
        <Form.ControlLabel>staticmethods:</Form.ControlLabel>
        <Form.Control accepter={TextArea} placeholder={"Default static will be used"} name="staticmethods" />
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>

      <Form.Group controlId="static3">
        <Form.ControlLabel>static3:</Form.ControlLabel>
        <Form.Control accepter={TextArea} placeholder={"Default static will be used"} name="static3" />
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>








      <Form.Group controlId="coverageNormal">
        <Form.ControlLabel>coverageNormal:</Form.ControlLabel>
        <Form.Control name="coverageNormal" />
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>


      <Form.Group controlId="coverageTumor">
        <Form.ControlLabel>coverageTumor:</Form.ControlLabel>
        <Form.Control name="coverageTumor" />
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>


      <Form.Group controlId="TMB">
        <Form.ControlLabel>TMB:</Form.ControlLabel>
        <Form.Control name="TMB" />
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>


      <Form.Group controlId="MSS">
        <Form.ControlLabel>MSS:</Form.ControlLabel>
        <Form.Control name="MSS" />
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>


      <Form.Group controlId="MSS_verdict">
        <Form.ControlLabel>MSS_verdict:</Form.ControlLabel>
        <Form.Control name="MSS_verdict" />
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>


      <Form.Group controlId="tumor_af_min">
        <Form.ControlLabel>tumor_af_min:</Form.ControlLabel>
        <Form.Control accepter={InputNumber} step={0.001} name="tumor_af_min" />
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>

      <Form.Group controlId="tumor_dp_min">
        <Form.ControlLabel>tumor_dp_min:</Form.ControlLabel>
        <Form.Control accepter={InputNumber} step={1} name="tumor_dp_min" />
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>

      <Form.Group controlId="control_dp_min">
        <Form.ControlLabel>control_dp_min:</Form.ControlLabel>
        <Form.Control accepter={InputNumber} step={1} name="control_dp_min" />
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>

      <Form.Group controlId="control_af_max">
        <Form.ControlLabel>control_af_max:</Form.ControlLabel>
        <Form.Control accepter={InputNumber} step={0.001} name="control_af_max" />
        <Form.HelpText tooltip>This is a tooltip description.</Form.HelpText>
      </Form.Group>


      <Form.Group controlId="datePicker">
        <Form.ControlLabel>День рождения:</Form.ControlLabel>
        <Form.Control name="dob" accepter={DatePicker} />
      </Form.Group>

      <Form.Group controlId="datePicker">
        <Form.ControlLabel>Дата отчета:</Form.ControlLabel>
        <Form.Control name="reportdate" accepter={DatePicker} />
      </Form.Group>

      <Form.Group controlId="selectPicker">
        <Form.ControlLabel>SelectPicker:</Form.ControlLabel>
        <Form.Control name="tumor_type" accepter={SelectPicker} data={cancerTypePickerData} />
      </Form.Group>

      <Form.Group controlId="file">
        <Form.ControlLabel>Uploader:</Form.ControlLabel>
        <Form.Control name="file" autoUpload={false} multiple={false} accepter={Uploader} />
      </Form.Group>

      <Form.Group controlId="style">
        <Form.ControlLabel>style:</Form.ControlLabel>
        <Form.Control accepter={SelectPicker} data={[
          {
            label: "fhm",
            value: "fhm"
          },
          {
            label: "red",
            value: "red"
          },
          {
            label: "arianda",
            value: "arianda"
          },
          {
            label: "default",
            value: "default"
          }
        ]} name="style" />
      </Form.Group>

      <Form.Group controlId="preprocessing">
        <Form.ControlLabel>Preprocess:</Form.ControlLabel>
        <Form.Control accepter={SelectPicker} data={[
          {
            label: "Disabled",
            value: "disabled"
          },
          {
            label: "FORMAT",
            value: "format"
          },
          {
            label: "INFO",
            value: "info"
          }
        ]} name="preprocessing" />
      </Form.Group>


      <Form.Group controlId="genomic_variant">
      <Form.ControlLabel>genomic_variant:</Form.ControlLabel>
      <Form.Control
        name="genomic_variant"
        accepter={GerminalInputControl}
      />
      </Form.Group>


      <Form.Group controlId="genomic_treatment">
      <Form.ControlLabel>genomic_treatment:</Form.ControlLabel>
      <Form.Control
        name="genomic_treatment"
        accepter={GerminalTreatmentInputControl}
      />
      </Form.Group>


      <Form.Group controlId="fusion_variant">
      <Form.ControlLabel>fusion_variant:</Form.ControlLabel>
      <Form.Control
        name="fusion_variant"
        accepter={FusionInputControl}
      />
      </Form.Group>


      <Form.Group controlId="fusion_treatment">
      <Form.ControlLabel>fusion_treatment:</Form.ControlLabel>
      <Form.Control
        name="fusion_treatment"
        accepter={FusionTreatmentInputControl}
      />
      </Form.Group>

      <Form.Group>
        <ButtonToolbar>
          <Button appearance="primary" type='submit'>Submit</Button>
        </ButtonToolbar>
      </Form.Group>
    </Form>
  </Panel>
}




function useTopLevelName() {
  const location = useLocation()
  const pathname = location.pathname
  return pathname.split("/")[1] || "home"
}

function App() {
  const active = useTopLevelName()
  const navigate = useNavigate()

  function onSelect(name) {
    navigate(`/${name}`)
  }

  if (!dataController.loggedIn)
    setTimeout(() => {
      navigate("/")
    })

  return <Container className='container'>
    <Header>
      <Navbar onSelect={onSelect} active={active} />
    </Header>
    <Content>
      <Outlet />
    </Content>
    <Footer>
      <h6>Разработчик:</h6>
      <p style={{ fontSize: "smaller" }}>ООО «Институт геномного анализа» — российская компания (торговая марка Zenome), специализирующаяся в области исследования и разработок программного обеспечения для хранения, передачи и обработки геномных данных.</p>
      <pre style={{ "white-space": "pre-wrap", fontSize: "smaller" }}>
        {`Общество с ограниченной ответственностью\n`}
        {`“Институт Геномного Анализа” (ООО “ИГА”)\n`}
        {`ОГРН 1167746592529 от 23 июня 2016 г.\n`}
        {`ИНН 7723454697\n`}
        {`КПП 772601001`}
      </pre>
    </Footer>
  </Container>
}




function SubNavBar() {
  const location = useLocation()
  const pathname = location.pathname
  const { id } = useParams()
  const navigate = useNavigate()

  if (pathname === "/results")
    return null

  if (pathname === `/results/${id}`)
    return null

  return <ButtonToolbar>
    <ButtonGroup>
      <Button onClick={() => navigate(`/results/${id}`)}>Назад</Button>
    </ButtonGroup>
  </ButtonToolbar>
}

function LoginScreen() {
  const [error, setError] = useState(false)
  const navigate = useNavigate()

  async function makeRequest(event) {
    const formData = new FormData(event.target.closest("form"))
    try {
      const response = await fetch("/login", {
        body: JSON.stringify(Object.fromEntries(formData)),
        method: "post",
        headers: { "Content-Type": "application/json", }
      })
      const data = await response.json()
      if (!data.ok) throw new Error("Auth error")
      await dataController.login()
      navigate("/home")
    } catch {
      setError(true)
    }
  }

  return <FlexboxGrid justify="center">
    <FlexboxGrid.Item colspan={12}>
      <Panel header={<h3>Login</h3>} bordered>
        <Form fluid>
          <Form.Group>
            <Form.ControlLabel>Email</Form.ControlLabel>
            <Form.Control name="email" />
          </Form.Group>
          <Form.Group>
            <Form.ControlLabel>Password</Form.ControlLabel>
            <Form.Control name="password" type="password" autoComplete="off" />
          </Form.Group>
          <Form.Group>
            <ButtonToolbar>
              <Button appearance="primary" onClick={makeRequest}>Sign in</Button>
            </ButtonToolbar>
          </Form.Group>
          {error && <Message showIcon type="error" header="Ошибка авторизации">
            Проверьте имя и пароль
          </Message>}
        </Form>
      </Panel>
    </FlexboxGrid.Item>
  </FlexboxGrid>
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <LoginScreen />
  },
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/home",
        element: <HomeScreen />
      },
      {
        path: "/source",
        element: <SourceData />
      },
      {
        path: "create",
        element: <CreateTaskScreen />
      },
      {
        path: "/results",
        element: <>
          <SubNavBar />
          <Outlet />
        </>,
        children: [
          {
            path: "/results",
            element: <ResultsList />
          },
          {
            path: "/results/:id",
            element: <ResultInfo />
          },
          {
            path: "/results/:id/postqc",
            element: <MultiQC type="postqc" />
          },
          {
            path: "/results/:id/preqc",
            element: <MultiQC type="preqc" />
          },
          {
            path: "/results/:id/igv",
            element: <IGV />
          }
        ]
      },
      {
        path: "/onco-report",
        element: <OncoReport />
      },
      {
        path: "/onco-reports/:id",
        element: <OncoReportLog />
      },
      {
        path: "/tapes-report",
        element: <TapesReport />
      },
      {
        path: "/tapes-reports/:id",
        element: <TapesReportLog />
      },
      {
        path: "/add-sample",
        element: <AddSampleToDb />
      }
    ]
  },
]);

export default function () {
  return <>
    <Helmet>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
    </Helmet>
    <RouterProvider router={router} />
  </>
};
