import React from "react"
import { List, FlexboxGrid, Progress } from 'rsuite';
import { statusDataExample } from '../statusDataExample';

const styleCenter = {
  display: 'flex',
  justifyContent: 'left',
  alignItems: 'left',
};

export function Running({ data = statusDataExample }) {

  function renderItem(item, index) {
    return <List.Item key={index} index={index + 1}>
      <FlexboxGrid>
        {/*icon*/}
        <FlexboxGrid.Item colspan={10} style={styleCenter}>
          {item.Tool}
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={8} style={styleCenter}>
          {item.Stage}
        </FlexboxGrid.Item>
        <FlexboxGrid.Item colspan={6} style={styleCenter}>
          <Progress percent={item.Progress} showInfo={false} />
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </List.Item>
  }

  return <List hover>
    {data.map(renderItem)}
  </List>
}