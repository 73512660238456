import { useLocation, useNavigate } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react"
import { Form,  List, Input, FlexboxGrid, ButtonToolbar, Button, Panel, Checkbox, CheckboxGroup, Grid, Row, Col } from 'rsuite';
import { SourceData } from "./SourceData"
import { InputPicker } from 'rsuite';
import controller from "../controller/info"
import { Loader } from 'rsuite';
import { useMediaQuery } from "../hooks/useMediaQuery"

const styleCenter = {
    display: 'flex',
    alignItems: 'center'
  };

function Line({ name, id, isMobile }) {
    return <FlexboxGrid key={id} style={{ display: "flex", "align-items": "stretch", "margin-bottom": 10 }}>
            <FlexboxGrid.Item style={styleCenter} colspan={isMobile ? 24 : 6}>{name}</FlexboxGrid.Item>
            <FlexboxGrid.Item style={styleCenter} colspan={isMobile ? 24 : 18}>
                <Form.Control name={id}/>
            </FlexboxGrid.Item>
    </FlexboxGrid>
}

function PickerLine({ name, id, data, isMobile }) {
    return <FlexboxGrid key={id} style={{ display: "flex", "align-items": "stretch", "margin-bottom": 10 }}>
            <FlexboxGrid.Item style={styleCenter} colspan={isMobile ? 24 : 6}>{name}</FlexboxGrid.Item>
            <FlexboxGrid.Item style={styleCenter} colspan={isMobile ? 24 : 18}>
                <Form.Control accepter={InputPicker} placeholder="Выберите данные" data={data} block  name={id}/>
            </FlexboxGrid.Item>
    </FlexboxGrid>
}

function CheckboxValued (props){
    const { defaultValue, value, ...rest } = props;
    return <Checkbox defaultChecked={defaultValue} checked={value} {...rest} />
}


function CheckboxLine({ name, id, data = [] }) {
    return <Form.Group key={id}>
            <Form.ControlLabel>{name}:</Form.ControlLabel>
            <Form.Control name={id} accepter={CheckboxGroup} inline>
                {
                    data.map(({ key, text }) => {
                        return <Checkbox key={key} value={key}>{text}</Checkbox>
                    })
                }
            </Form.Control>
            <Form.HelpText>This default description.</Form.HelpText>
        </Form.Group>
}



export function CreateTaskScreen() {
  const isMobile = !useMediaQuery('(min-width: 576px)')
  const navigate = useNavigate()
  const [ submitting, setSubmitting ] = useState(false)
  const [ done, setDone ] = useState(false)
  const srcData = controller.useSourceList()
  const intervalsData = controller.useIntervalsList()
  const [ formValues, setFormValues ] = useState({})

  function updateFormValues(values) {
    if (formValues.source !== values.source) {
        const data = srcData.find(item => {
            return item.id == values.source
        })
        if (data) {
            values.fullname = data.extra.fullname
            values.seq_platform = data.extra.seq_platform
            values.flow_id = data.extra.flow_id
            values.cell_number = data.extra.cell_number
            values.barcode = data.extra.barcode
            values.sample_id = data.extra.sample_id
        }
    }
    console.log(values)
    setFormValues(values)
  }

  const sourceList = srcData.map(function (value) {
        return {
            label: value.extra.fullname,
            value: value.id
        }
  })

  const intervalList = intervalsData.map(function (value) {
    return {
        label: value.name,
        value: value.id
    }
})

  console.log(sourceList)

  const main = <Panel header="Заполните форму для запуска задачи">
            <PickerLine isMobile={isMobile} id="source" name="FastQ" data={sourceList}/>
            <PickerLine isMobile={isMobile} id="intervals" name="BED" data={intervalList}/>
            <Line isMobile={isMobile} id="fullname" name="Название"/>
            <Line isMobile={isMobile} id="seq_platform" name="Секвенатор"/>
            <Line isMobile={isMobile} id="flow_id" name="ID проточной ячейки"/>
            <Line isMobile={isMobile} id="cell_number" name="Дорожка"/>
            <Line isMobile={isMobile} id="barcode" name="Баркод"/>
            <Line isMobile={isMobile} id="sample_id" name="ID образца"/>
            <CheckboxLine isMobile={isMobile} id="options" name="Параметры" data={[
                { key: "stararriba", text: "Найти фьюжены" }
            ]}/>
  </Panel>

  //const selector = <SourceData/>

  async function onSubmit() {
    setSubmitting(true)
    const response = await fetch(`/api/create/task`, {
        headers: {
            "Content-Type": "application/json",
        },
        method: "POST",
        body: JSON.stringify(formValues)
    })
    const json = await response.json()
    if (json.ok === false) {
        alert("Error")
        setSubmitting(false)
    } else {
        await controller.fetch()
        navigate(`/results/${json.id}`)
    }
  }

  if (done) {
    return <div style={{ display: "grid", "justify-content": "center", "align-items": "center", height: "40vh" }}>
        Task created
    </div>
  }

  if (submitting) {
    return <div style={{ display: "grid", "justify-content": "center", "align-items": "center", height: "40vh" }}>
        <Loader size="lg" content="Submitting data" />
    </div>
  }

  return <Form formValue={formValues} onChange={updateFormValues}>
    {main}
    <ButtonToolbar>
        <Button onClick={onSubmit} appearance="primary">Создать задачу</Button>
    </ButtonToolbar>
    <div style={{ marginBottom: 10 }}></div>
  </Form>
}